import React from 'react';
import Header from '../Common/Header';
import MainContent from './MainContent';
// import Footer from './Footer';

function AgentDashboard() {
  return (
    <div>
      <Header />
      <MainContent />
      {/* <Footer /> */}
    </div>
  );
}

export default AgentDashboard;