import React, { useState } from 'react';
import Select from 'react-select';
import { Bar } from 'react-chartjs-2';
import { FaCalendarAlt } from 'react-icons/fa'; // Import an icon library

const data = {
  labels: ['JAN', 'FEB', 'MAR', 'APR', 'MAY', 'JUN', 'JUL', 'AUG', 'SEP', 'OCT', 'NOV', 'DEC'],
  datasets: [
    {
      type: 'bar',
      label: 'Sales',
      data: Array.from({ length: 12 }, () => Math.floor(Math.random() * 100)),
      backgroundColor: 'rgba(54, 162, 235, 0.6)',
      borderColor: 'rgba(54, 162, 235, 1)',
      borderWidth: 1,
    },
    {
      type: 'line',
      label: 'Trend',
      data: Array.from({ length: 12 }, () => Math.floor(Math.random() * 100)),
      borderColor: 'rgba(255, 99, 132, 1)',
      borderWidth: 2,
      fill: false,
    },
  ],
};

const options = [
  { value: '2021', label: 'Year 2021' },
  { value: '2020', label: 'Year 2020' },
  { value: '2019', label: 'Year 2019' },
  { value: '2018', label: 'Year 2018' },
  { value: '2017', label: 'Year 2017' },
  { value: '2016', label: 'Year 2016' },
  { value: '2015', label: 'Year 2015' },
];

const customStyles = {
  control: (base) => ({
    ...base,
    display: 'flex',
    alignItems: 'center',
    padding: '5px',
    borderRadius: '8px',
    borderColor: '#ccc',
    boxShadow: 'none',
    '&:hover': {
      borderColor: '#aaa',
    },
  }),
  placeholder: (base) => ({
    ...base,
    display: 'flex',
    alignItems: 'center',
  }),
};

const SalesChart = () => {
  const [selectedOption, setSelectedOption] = useState(null);

  const handleChange = (selectedOption) => {
    setSelectedOption(selectedOption);
  };

  return (
    <div style={{
        padding: '20px',
        backgroundColor: '#fff',
        borderRadius: '8px',
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
        height: '100%'
      }}>
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <h3 style={{ margin: 0 }}></h3>
          <div style={{ width: '200px' }}>
            <Select
              value={selectedOption}
              onChange={handleChange}
              options={options}
              placeholder={<div><FaCalendarAlt style={{ marginRight: '8px' }} />Select Option</div>}
              styles={customStyles}
            />
          </div>
        </div>
        <div style={{ height: 'calc(100% - 50px)' }}>
          <Bar data={data} options={{ maintainAspectRatio: false }} />
        </div>
      </div>
    );
  };

export default SalesChart;