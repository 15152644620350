import axios from "axios";
import relayService from "../Admin/AppProviders/Axios/hook";
import React, { useRef, useEffect, useState } from "react";
import { Button, Card, CardBody, CardHeader, CardTitle, Col, Dropdown, DropdownButton, Form, Modal, Row } from "react-bootstrap";
import { Justify } from "react-bootstrap-icons";
import DataTable from "react-data-table-component";
import ToggleButton from 'react-toggle-button'
import { jwtDecode } from "jwt-decode";
import { ToastContainer, toast } from "react-toastify";
import { Bar } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import SalesChart from './SalesChart';
import CustomChart from "./CustomChart";
import InboundContent from "./InboundContent";
import 'react-toastify/dist/ReactToastify.css';

const MainContent = () => {
    const [modalOpen, setModalOpen] = useState(false);
    const [selectedOption, setSelectedOption] = useState('');
    const [buttonText, setButtonText] = useState('Break In');
    const [buttonColor, setButtonColor] = useState('primary');
    const [formStatus, setFormStatus] = useState(false);
    const [cardsVisible, setCardsVisible] = useState(false);
    const [breakInVisible, setBreakInVisible] = useState(true);
    const [formSubmitted, setFormSubmitted] = useState(false);
    const [fetchClicked, setFetchClicked] = useState(localStorage.getItem('fetchClicked') === 'true');
    const [showDispositionForm, setShowDispositionForm] = useState(false);
    const [isCalling, setIsCalling] = useState(false);
    const [layoutShrink, setLayoutShrink] = useState(false);
    const [showSchemesModal, setShowSchemesModal] = useState(false);
    const [showCampaignsModal, setShowCampaignsModal] = useState(false);
    const [callSuccess, setCallSuccess] = useState(false);
    const [showTicketsModal, setShowTicketsModal] = useState(false);
    const [businessMetrics, setBusinessMetrics] = useState({});

    const handleBreakIn = () => {
        if (buttonText === 'BreakOut') {
            setButtonText('Break In');
            setButtonColor('primary');
            setSelectedOption('');
            setModalOpen(false);
        } else {
            setModalOpen(true);
        }
    };

    const customStyles = {
        headCells: {
            style: {
                fontWeight: 'bold',
            },
        },

    };

    const handleBreakOut = () => {
        setButtonText('BreakOut');
        setButtonColor('danger');
        setModalOpen(false);
    };

    const handleOptionChange = (event) => {
        setSelectedOption(event.target.value);
    };

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    const columns = [
        {
            name: <div style={{ fontWeight: "bold" }}>ID</div>,
            selector: row => row.OfferId,
            sortable: true,
            width: 'auto' // Adjust the width as needed
        },
        {
            name: <div style={{ textAlign: "center", fontWeight: "bold" }}>Name</div>,
            selector: row => row.Title,
            sortable: true,
            width: 'auto' // Adjust the width as needed
        },
        {
            name: <div style={{ textAlign: "center", fontWeight: "bold" }}>Start Date</div>,
            selector: row => formatDate(row.OStartDate),
            sortable: true,
            width: 'auto' // Adjust the width as needed
        },
        {
            name: <div style={{ textAlign: "center", fontWeight: "bold" }}>Discount</div>,
            selector: row => row.DiscountPercentage,
            sortable: true,
            width: 'auto' // Adjust the width as needed
        }
    ];

    const CampaignsCol = [
        {
            name: <div style={{ textAlign: "center", fontWeight: "bold" }}>ID</div>,
            selector: row => row.CampaignId,
            sortable: true,
            width: 'auto'
        },
        {
            name: <div style={{ textAlign: "center", fontWeight: "bold" }}>Name</div>,
            selector: row => row.Title,
            sortable: true,
            width: 'auto'
        },
        {
            name: <div style={{ textAlign: "center", fontWeight: "bold" }}>Type</div>,
            selector: row => row.CampaignType,
            sortable: true,
            width: 'auto'
        },
        {
            name: <div style={{ textAlign: "center", fontWeight: "bold" }}>Discount</div>,
            selector: row => row.DiscountPercentage,
            sortable: true,
            width: 'auto'
        }
    ];

    const ticketColumns = [
        {
            name: <div style={{ textAlign: "center", fontWeight: "bold" }}>Ticket ID</div>,
            selector: row => row.TicketId,
            sortable: true,
            width: 'auto'
        },
        {
            name: <div style={{ textAlign: "center", fontWeight: "bold" }}>Subject</div>,
            selector: row => row.Subject,
            sortable: true,
            width: 'auto'
        },
        {
            name: <div style={{ textAlign: "center", fontWeight: "bold" }}>Description</div>,
            selector: row => row.TicketDescription.text,
            sortable: true,
            width: 'auto'
        },
        {
            name: <div style={{ textAlign: "center", fontWeight: "bold" }}>Status</div>,
            selector: row => row.TicketStatus,
            sortable: true,
            width: 'auto'
        },
        {
            name: <div style={{ textAlign: "center", fontWeight: "bold" }}>Date</div>,
            selector: row => new Date(row.DueDate).toLocaleDateString(),
            sortable: true,
            width: 'auto'
        }

    ];

    const order = [
        {
            name: <div style={{ textAlign: "center", fontWeight: "bold" }}>Order ID</div>,
            selector: row => row.OrderId,
            sortable: true
        },
        {
            name: <div style={{ textAlign: "center", fontWeight: "bold" }}>Product</div>,
            selector: row => row.Product,
            sortable: true
        },
        {
            name: <div style={{ textAlign: "center", fontWeight: "bold" }}>Quantity</div>,
            selector: row => row.Quantity.toLocaleString(),
            sortable: true
        },
        {
            name: <div style={{ textAlign: "center", fontWeight: "bold" }}>UnitPrice</div>,
            selector: row => row.UnitPrice.toLocaleString(),
            sortable: true
        },
        {
            name: <div style={{ textAlign: "center", fontWeight: "bold" }}>TotalPrice</div>,
            selector: row => row.TotalPrice.toLocaleString(),
            sortable: true
        },
        {
            name: <div style={{ textAlign: "center", fontWeight: "bold" }}>SalesDate</div>,
            selector: row => new Date(row.SalesDate).toLocaleDateString(),
            sortable: true
        }

    ]

    const [self, setSelf] = useState({ value: true });
    // const actions = () => {
    //     return (
    //         <ToggleButton
    //             value={self.value || false}
    //             onToggle={(value) => {
    //                 setSelf({
    //                     ...self,
    //                     value: !value,
    //                 });
    //             }}
    //         />
    //     );
    // };

    // const CampaignData = [
    //     { id: '1', name: 'Demo 1', actions: actions() },
    //     { id: '2', name: 'Demo 1', actions: actions() },
    //     { id: '3', name: 'Demo 1', actions: actions() },
    //     { id: '4', name: 'Demo 1', actions: actions() },
    //     { id: '5', name: 'Demo 1', actions: actions() }
    // ];

    const [serach, setSeacrch] = useState('')

    const [customer, setCustomer] = useState([])
    const [offers, setOffers] = useState([])
    const [campaigns, setCampaigns] = useState([])
    const [orders, setOrders] = useState([])
    const [interactionData, setInteractionData] = useState([]);
    const [outbound, setOutbound] = useState(true)
    const [interactionModalShow, setInteractionModalShow] = useState(false);

    const handleInteractionClick = async () => {
        try {
            const response = await relayService({
                url: '/tickets/fetch',
                method: 'POST',
                data: {
                    TenantId: 0,
                    AssignedTo: 'Ranjith'
                }
            });

            if (response.data.status === 'Success') {
                setInteractionData(response.data.message);
                setInteractionModalShow(true);
                // setCardsVisible(true);
                // setBreakInVisible(false);
                // setOutbound(false);
                // console.log(customerResponse, "response");
                // setCustomer(customerResponse.data.message[0]);
                // getOffers();
                // getCampaigns();
                // getOrders();
            } else {
                console.error('Failed to fetch tickets:', response.data.message);
                toast.error('Failed to fetch tickets.');
            }
        } catch (error) {
            console.error('Error fetching tickets:', error);
            toast.error('Error fetching tickets.');
        }
    };

    const handleCloseModal = () => {
        setInteractionModalShow(false);
    };
    const [selectedDropdown, setSelectedDropdown] = useState(localStorage.getItem('selectedDropdown') || 'Outbound');

    const handleFetchClick = async () => {
        setFetchClicked(true);
        localStorage.setItem('fetchClicked', 'true');
        getCustomerDetails();
    };
    const [content, setContent] = useState(null);
    const [rid, setRid] = useState(null);

    useEffect(() => {
        const dropdownValue = localStorage.getItem('selectedDropdown');
        if (dropdownValue === 'Inbound') {
            setContent(<InboundContent />);
        } else {
            setContent(null);
        }
    }, []);
    const handleDropdownChange = async (eventKey) => {
        if (fetchClicked && eventKey === 'Outbound') {
            return;
        }
        localStorage.setItem('selectedDropdown', eventKey);
        setSelectedDropdown(eventKey);
        setSearchInput('');

        const actionID = '1234567';
        const queue = '8001';
        const SIPInbound = 'SIP/9999';
        const baseAPI = `${process.env.REACT_APP_API_BASE_URL}/Voice/`;

        try {
            if (eventKey === 'Inbound') {
                const url = `${baseAPI}queueAdd?ActionID=${encodeURIComponent(actionID)}&Queue=${encodeURIComponent(queue)}&Interface=${encodeURIComponent(SIPInbound)}`;
                const response = await fetch(url);
                const data = await response.json();
                console.log('Queue added:', data);
                setContent(<InboundContent />);
                setSearchInput('');
                setCardsVisible(false);
                //  toast.success('Queue successfully added!');
            } else {
                const url = `${baseAPI}queueRemove?ActionID=${encodeURIComponent(actionID)}&Queue=${encodeURIComponent(queue)}&Interface=${encodeURIComponent(SIPInbound)}`;
                const response = await fetch(url);
                const data = await response.json();
                console.log('Queue removed:', data);
                // toast.success('Queue successfully removed!');   
                setContent(null);
                handleSearchClick();
                setCardsVisible(false);
                setSearchInput(''); // Clear content when other options are selected
                //  toast.success('Queue successfully removed!'); 
            }
        } catch (error) {
            console.error('Error handling queue:', error);
        }
    };



    const [searchInput, setSearchInput] = useState('');

    const handleSearchChange = (e) => {
        setSearchInput(e.target.value);
    };

    const handleSearchClick = async () => {
        try {
            const response = await relayService({
                url: `/Customer/fetch`,
                method: "POST",
                headers: { "Content-Type": "application/json" },
                data: { TenantId: 0, Phone: searchInput },
            });
            if (response.data?.message?.length < 1) {
                toast.error('Customer not found!');
                return
            }
            if (response && response.data?.message?.length > 0) {
                setCustomer(response.data.message[0]);
                const customerId = response.data.message[0].CustomerId;
                localStorage.setItem('customerId', customerId);
                const Phone = response.data.message[0].Phone;
                localStorage.setItem('Phone', Phone);
                console.log("Customer details fetched successfully:", response.data.message[0]);

                setCardsVisible(true);
                setBreakInVisible(false);
                setOutbound(false);
                setSelectedDropdown('Inbound'); // Set the dropdown to 'Inbound' after successful search
                setContent(<InboundContent />);
                // console.log(customerResponse, "response");
                // setCustomer(customerResponse.data.message[0]);
                getOffers();
                getCampaigns();
                getOrders();
            } else {
                console.log("No customer details found.");
            }
        } catch (error) {
            console.error("Error fetching customer details:", error);
        }
    };

    const getCustomerDetails = async () => {
        console.log("Fetching sales campaigns to get customer ID...");
        try {
            let fetchedRid = localStorage.getItem('fetchedRid');
            let customerId;
            let Notes;

            if (!fetchedRid) {
                // First API call to get the customer ID
                const salesCampaignResponse = await relayService({
                    url: `/SalesCampaigns/fetch`,
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    data: { TenantId: 0, UserId: null }
                });

                if (salesCampaignResponse && salesCampaignResponse.data?.message?.length > 0) {
                    customerId = salesCampaignResponse.data.message[0].CustomerId;
                    fetchedRid = salesCampaignResponse.data.message[0].rid;
                    Notes = salesCampaignResponse.data.message[0].Notes;

                    console.log("fetchedRid:", fetchedRid);
                    localStorage.setItem('customerId', customerId);
                    localStorage.setItem('Notes', Notes);
                    localStorage.setItem('fetchedRid', fetchedRid);
                }
                const token = localStorage.getItem('token');
                const decoded = jwtDecode(token);
                await relayService({
                    url: `/SalesCampaigns/update?rid=${fetchedRid}`,
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    data: { TenantId: 0, UserId: decoded.userId.UserId }
                });

                console.log("Fetching customer details...");
                const customerResponse = await relayService({
                    url: `/Customer/fetch`,
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    data: { TenantId: 0, CustomerId: customerId },
                });

                if (customerResponse && customerResponse.data?.message?.length > 0) {
                    const Phone = customerResponse.data.message[0].Phone;
                    localStorage.setItem('Phone', Phone);
                    setCardsVisible(true);
                    setBreakInVisible(false);
                    setOutbound(false);
                    console.log(customerResponse, "response");
                    setCustomer(customerResponse.data.message[0]);
                    getOffers();
                    getCampaigns();
                    getOrders();
                }
            }

            else {
                setRid(fetchedRid);

                const salesCampaignResponse = await relayService({
                    url: `/SalesCampaigns/fetch`,
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    data: { TenantId: 0, rid: fetchedRid }
                });

                if (salesCampaignResponse && salesCampaignResponse.data?.message?.length > 0) {
                    customerId = salesCampaignResponse.data.message[0].CustomerId;
                    localStorage.setItem('customerId', customerId);

                }

                const token = localStorage.getItem('token');
                const decoded = jwtDecode(token);
                await relayService({
                    url: `/SalesCampaigns/update?rid=${fetchedRid}`,
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    data: { TenantId: 0, UserId: decoded.userId.UserId }
                });

                console.log("Fetching customer details...");
                const customerResponse = await relayService({
                    url: `/Customer/fetch`,
                    method: "POST",
                    headers: { "Content-Type": "application/json" },
                    data: { TenantId: 0, CustomerId: customerId },
                });

                if (customerResponse && customerResponse.data?.message?.length > 0) {
                    setCardsVisible(true);
                    setBreakInVisible(false);
                    setOutbound(false);
                    console.log(customerResponse, "response");
                    setCustomer(customerResponse.data.message[0]);
                    getOffers();
                    getCampaigns();
                    getOrders();
                }
            }
        } catch (err) {
            console.error("Error fetching data:", err);
        }
    };

    useEffect(() => {
        if (fetchClicked) {
            getCustomerDetails();
        }
    }, [fetchClicked]);


    const getOffers = async () => {
        console.log("response");
        try {
            // Make a GET request to retrieve Customer Details
            const response = await relayService({
                url: `/Offers/fetch`,
                method: "POST",
                headers: { "Content-Type": "application/json" },
                data: { TenantId: 0 },
            });
            if (response) {
                if (response?.data?.message?.length > 0) {
                    console.log(response, "offers");
                    setOffers(response?.data?.message);
                    //return response;
                }
            }
        } catch (err) {
            // Show error message if request fails
        }
    };

    const getCampaigns = async () => {
        console.log("response");
        try {
            // Make a GET request to retrieve Customer Details
            const response = await relayService({
                url: `/Campaigns/fetch`,
                method: "POST",
                headers: { "Content-Type": "application/json" },
                data: { TenantId: 0 },
            });
            if (response) {
                if (response?.data?.message?.length > 0) {
                    console.log(response, "campaigns");
                    setCampaigns(response?.data?.message);
                    //return response;
                }
            }
        } catch (err) {
            // Show error message if request fails
        }
    };

    let Note = localStorage.getItem('Notes');
    console.log("Note", Note);

    const getOrders = async () => {
        console.log("response");
        try {
            // Make a GET request to retrieve Customer Details
            const CID = localStorage.getItem('customerId');
            //const CID = localStorage.getItem('customerId');
            const custID = parseInt(CID);
            console.log('CID', parseInt(CID))
            const response = await relayService({
                url: `/Sales/fetch`,
                method: "POST",
                headers: { "Content-Type": "application/json" },
                data: { TenantId: 0, CustomerId: CID },
            });
            if (response) {
                if (response?.data?.message?.length > 0) {
                    console.log(response, "sales");
                    setOrders(response?.data?.message);
                    //return response;
                }
            }
        } catch (err) {
            // Show error message if request fails
        }
    };

    const handleCallClick = async () => {
        if (isCalling) return;
        setIsCalling(true);
        setIsCalling(true);
        setLayoutShrink(true);
        toast.success('Call initiated!');

        const token = localStorage.getItem('token');
        const decoded = jwtDecode(token);
        const sipPhone = `${decoded.userId.SipPhone}`;
        const actionID = '1234567';
        const phoneNumber = customer?.Phone;
        const url = `/Voice/c2cSIP?ActionID=${encodeURIComponent(actionID)}&sipAgentID=${encodeURIComponent(sipPhone)}&NumberToBeCalled=${encodeURIComponent(phoneNumber)}`;

        try {
            const response = await relayService({
                url: url,
                method: "GET"
            });
            console.log('Call initiated:', response.data);
            toast.success('Call initiated successfully!');
            setShowDispositionForm(true);
            setCallSuccess(true);
        } catch (error) {
            console.error('Error initiating call:', error);
            toast.error('Error initiating call.');
            setShowDispositionForm(true);
        } finally {
            setIsCalling(false);
        }
    };

    const [inBoundCall, setInBoundCAll] = useState([])
    const [callerId, setCallerId] = useState('');
    const [isLive, setIsLive] = useState(false);


    // Define columns for DataTable


    // Add useEffect to fetch tickets when modal is opened
    useEffect(() => {
        if (showTicketsModal) {
            fetchTickets();
        }
    }, [showTicketsModal]);

    // Function to fetch tickets
    const fetchTickets = async () => {
        const phone = localStorage.getItem('Phone');
        try {
            const response = await relayService({
                url: '/tickets/fetch',
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: { TenantId: 0, Owner: phone }
            });
            if (response.data && response.data.message) {
                setTickets(response.data.message);
            }
        } catch (error) {
            console.error('Failed to fetch tickets:', error);
        }
    };

    // Add state for tickets data
    const [tickets, setTickets] = useState([]);

    // useEffect(() => {
    //     if (selectedDropdown !== 'Inbound') {
    //         return;
    //     }

    //     let intervalId = null;

    //     const fetchCallData = () => {
    //         fetch('http://localhost:42300/Voice/cdrreports?StartDate=' + encodeURIComponent(new Date().toISOString()) + '&EndDate=' + encodeURIComponent(new Date().toISOString()) + '&Live=true')
    //             .then(response => response.json())
    //             .then(data => {
    //                 console.log(data);
    //                 if (data.Live) {
    //                     setCallerId(data.CallerId);
    //                     setIsLive(true);
    //                     clearInterval(intervalId);
    //                 } else {
    //                     setIsLive(false);
    //                     if (!intervalId) {
    //                         intervalId = setInterval(fetchCallData, 10000);
    //                     }
    //                 }
    //             })
    //             .catch(error => {
    //                 console.error('Error fetching call data:', error);
    //                 if (!intervalId) {
    //                     intervalId = setInterval(fetchCallData, 10000);
    //                 }
    //             });
    //     };
    //     fetchCallData();

    //     return () => {
    //         if (intervalId) {
    //             clearInterval(intervalId);
    //         }
    //     };
    // }, [selectedDropdown]);
    const maskedPhone = customer?.Phone ? customer.Phone.slice(0, 0) + '*****' + customer.Phone.slice(-5) : '';
    const [formData, setFormData] = useState({
        TenantId: 0,
        TicketType: '',
        TicketSource: 'Voice',
        Subject: '',
        TicketDescription: '',
        Priority: 'Medium',
        Severity: 'Medium',
        Category: 'Sales',
        SubCategory: 'Order',
        Owner: '',
        AssignedTo: '',
        TicketStatus: '',
        DueDate: '',
        Process: 'Adani',
        Team: 'Adani',
        Escalated: "on" ? true : false,
    });
    const [ticketDescription, setTicketDescription] = useState('');
    useEffect(() => {
        const token = localStorage.getItem('token');
        const decoded = jwtDecode(token);
        const phone = localStorage.getItem('Phone');
        setFormData(prevState => ({
            ...prevState,
            Owner: phone,
            AssignedTo: decoded.userId.UserName
        }));
    }, []);
    const handleChange = (e) => {
        const { name, type, checked, value } = e.target;
        let formattedValue = value;
        if (name === "DueDate") {
            formattedValue = new Date(value).toISOString();
        }
        if (type === "checkbox") {
            setFormData(prevState => ({
                ...prevState,
                [name]: checked
            }));
        } else if (name === "TicketDescription") {
            setTicketDescription(value);
        } else {
            setFormData(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const submissionData = {
                ...formData,
                TicketDescription: { text: ticketDescription }
            };
            const response = await relayService({
                url: '/tickets/add',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: submissionData
            });
            const data = await response.data;
            console.log('Submission successful', data);
            const fetchedRid = localStorage.getItem('fetchedRid');
            // Additional API call to update SalesCampaigns
            if (fetchedRid) {
                const updateResponse = await relayService({
                    url: `/SalesCampaigns/update?rid=${fetchedRid}`,
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    data: {
                        TenantId: 0,
                        UserFetchTime: new Date().toISOString().split('T')[0],
                        Active: false,
                        Tickets: data.message.TicketId,
                        Attemps: 1
                    }
                });
            }

            setCardsVisible(false);

            setLayoutShrink(false);
            setFetchClicked(false);
            setShowDispositionForm(false);
            setFormData({
                TenantId: 0,
                TicketType: '',
                TicketSource: '',
                Subject: '',
                TicketDescription: '',
                Priority: '',
                Severity: '',
                Category: '',
                SubCategory: '',
                Owner: '',
                AssignedTo: '',
                TicketStatus: '',
                DueDate: '',
                Process: 'Adani',
                Team: 'Adani',
                Escalated: false,
            });
            setTicketDescription('');
            setFetchClicked(false);
            setBreakInVisible(true);
            localStorage.setItem('fetchClicked', 'false');
            localStorage.removeItem('fetchedRid');
            localStorage.removeItem('customerId');
            localStorage.removeItem('Notes');
            toast.success('Ticket added successfully!');
        } catch (error) {
            console.error('Error submitting form', error);
            toast.error('Error submitting ticket.');
        }
    };
    const [totalModalShow, setTotalModalShow] = useState(false);
    const [followUpModalShow, setFollowUpModalShow] = useState(false);
    const [pendingModalShow, setPendingModalShow] = useState(false);

    const RecommendationChart = () => {
        const canvasRef = useRef(null);

        useEffect(() => {
            const ctx = canvasRef.current.getContext('2d');
            const chart = new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: ['January', 'February', 'March', 'April', 'May', 'June'],
                    datasets: [{
                        label: 'Cement Sales',
                        data: [65, 59, 80, 81, 56, 55],
                        backgroundColor: [
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                        ],
                        borderColor: [
                            'rgba(54, 162, 235, 1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(54, 162, 235, 1)',
                        ],
                        borderWidth: 1
                    }]
                },
                options: {
                    scales: {
                        y: {
                            beginAtZero: true
                        }
                    }
                }
            });

            return () => chart.destroy();
        }, []);

        return <canvas ref={canvasRef}></canvas>;
    };

    useEffect(() => {
        const fetchBusinessMetrics = async () => {
            try {
                const response = await relayService({
                    url: 'ZAIServices/GetCustomerPurchaseSummary',
                    method: 'GET'
                });

                if (response.data.Status === "Success") {
                    setBusinessMetrics(response.data.message);
                } else {
                    toast.error("Failed to fetch business metrics");
                }
            } catch (error) {
                toast.error("Error fetching business metrics");
            }
        };

        fetchBusinessMetrics();
    }, []);

    return (

        <>


            <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />
            <main id="main" className="main" style={{ marginLeft: "0px", padding: "10px 15px" }}>
                <div className="row d-flex">
                    <div className="col-md-6">
                        <div className="card">
                            <div className="card-body" >
                                <Button variant="link" style={{ padding: 0 }} onClick={() => setTotalModalShow(true)}>
                                    <span className="badge bg-primary" style={{ marginRight: "10px" }}>
                                        <i className="bi bi-star me-1"></i> Total - 10
                                    </span>
                                </Button>
                                <Modal show={totalModalShow} onHide={() => setTotalModalShow(false)}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Total Details</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        {/* Content for Total Details */}
                                    </Modal.Body>
                                </Modal>
                                <span
                                    className="badge bg-success"
                                    style={{ marginRight: "10px", cursor: "pointer" }}
                                    onClick={handleInteractionClick}
                                >
                                    <i className="bi bi-check-circle me-1"></i> Interaction - 5
                                </span>
                                <Button variant="link" style={{ padding: 0 }} onClick={() => setFollowUpModalShow(true)}>
                                    <span className="badge bg-warning text-dark" style={{ marginRight: "10px" }}>
                                        <i className="bi bi-exclamation-triangle me-1"></i> Follow Up - 0
                                    </span>
                                </Button>
                                <Modal show={followUpModalShow} onHide={() => setFollowUpModalShow(false)}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Follow Up Details</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        {/* Content for Follow Up Details */}
                                    </Modal.Body>
                                </Modal>
                                {/* <span
                  className="badge bg-secondary"
                  style={{ marginRight: "10px" }}>
                  <i className="bi bi-collection me-1"></i>
                </span> */}
                                <Button variant="link" style={{ padding: 0 }} onClick={() => setPendingModalShow(true)}>
                                    <span className="badge bg-danger" style={{ marginRight: "10px" }}>
                                        <i className="bi bi-exclamation-octagon me-1"></i> Pending - 0
                                    </span>
                                </Button>
                                <Modal show={pendingModalShow} onHide={() => setPendingModalShow(false)}>
                                    <Modal.Header closeButton>
                                        <Modal.Title>Pending Details</Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        {/* Content for Pending Details */}
                                    </Modal.Body>
                                </Modal>
                                {/* <span
                  className="badge bg-info text-dark"
                  style={{ marginRight: "10px" }}>
                  <i className="bi bi-info-circle me-1"></i> Callback -  10
                </span> */}
                            </div>
                        </div>
                    </div>
                    {/* End Page Title */}
                    <div className="card col-md-6">
                        <div className="d-flex justify-content-between ">
                            <div className="justify-content-start p-2 ">
                                {selectedOption.length > 0 && (
                                    <p className="moveText" style={{ fontWeight: "bold", fontSize: "15px", color: "orange", animation: "moveText 2s linear infinite" }}>Your on {selectedOption} break.</p>
                                )}
                                <div className="input-group">
                                    <input
                                        type="text"
                                        placeholder="Search..."
                                        className="form-control"
                                        value={searchInput}
                                        onChange={handleSearchChange}
                                    />
                                    <button className="btn btn-outline-secondary" type="button" id="button-addon2" onClick={handleSearchClick}>
                                        <i className="bi bi-search"></i>
                                    </button>
                                </div>
                            </div>
                            <div className="d-flex justify-content-end p-2 gap-2">

                                <Button onClick={handleFetchClick} variant="primary" style={{ display: (selectedDropdown === 'Inbound' || fetchClicked || (selectedOption !== '' && buttonText === 'BreakOut')) ? 'none' : 'inline-block' }}>
                                    Fetch
                                </Button>
                                <>
                                    <div className="">
                                        <DropdownButton id="inbound-dropdown" title={selectedDropdown} style={{ display: (selectedOption !== '' && buttonText === 'BreakOut') ? 'none' : 'inline-block' }}>
                                            <Dropdown.Item onClick={() => handleDropdownChange('Inbound')} disabled={fetchClicked}>Inbound</Dropdown.Item>
                                            <Dropdown.Item onClick={() => handleDropdownChange('Outbound')} disabled={fetchClicked}>Outbound</Dropdown.Item>
                                        </DropdownButton>

                                    </div>
                                    <div>
                                        {breakInVisible && (
                                            <Button onClick={handleBreakIn} variant={buttonColor}>
                                                {buttonText}
                                            </Button>
                                        )}
                                        <Modal show={modalOpen} onHide={() => setModalOpen(false)}>
                                            <Modal.Header closeButton>
                                                <Modal.Title>Select Break Option</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <Form className="d-flex gap-3">
                                                    <Form.Check
                                                        type="radio"
                                                        id="lunch"
                                                        label="Lunch"
                                                        name="breakOption"
                                                        value="lunch"
                                                        checked={selectedOption === 'lunch'}
                                                        onChange={handleOptionChange}
                                                    />
                                                    <Form.Check
                                                        type="radio"
                                                        id="tea"
                                                        label="Tea"
                                                        name="breakOption"
                                                        value="tea"
                                                        checked={selectedOption === 'tea'}
                                                        onChange={handleOptionChange}
                                                    />
                                                    <Form.Check
                                                        type="radio"
                                                        id="shortBreak"
                                                        label="Short Break"
                                                        name="breakOption"
                                                        value="shortBreak"
                                                        checked={selectedOption === 'shortBreak'}
                                                        onChange={handleOptionChange}
                                                    />
                                                </Form>
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button variant="secondary" onClick={() => setModalOpen(false)}>
                                                    Close
                                                </Button>
                                                <Button variant="success" onClick={handleBreakOut}>
                                                    Break In
                                                </Button>
                                            </Modal.Footer>
                                        </Modal>
                                    </div>
                                </>
                            </div>
                        </div>
                    </div>
                </div>
                {content}
                {/* <InboundContent/> */}
                {cardsVisible && (
                    <>
                        {/* {fetchClicked && ( */}
                        <>
                            <Row className="g-2">
                                <Col md={4} className="">
                                    <Card>
                                        <CardHeader className="fw-bold" style={{ backgroundColor: "rgb(245 245 244)", marginBottom: "8px" }}>
                                            Customer Details
                                        </CardHeader>
                                        <CardBody style={{ height: "64vh", overflowY: "auto" }} >
                                            <div className="row" >

                                                <div className="col-md-12" style={{ padding: "5px 10%", borderLeft: "5px solid #198754" }}>

                                                    <h6>Name : <span style={{ float: 'right' }}>{customer?.CustomerName}</span></h6>

                                                    <h6>Number : <span style={{ float: 'right' }}>{maskedPhone}</span></h6>
                                                    {isLive && <h6>Number : <span style={{ float: 'right' }}>{callerId}</span></h6>}

                                                    <h6>Customer Type : <span style={{ float: 'right', color: 'red' }}>{customer?.CustomerType}</span></h6>
                                                    <h6>Email : <span style={{ float: 'right' }}>{customer?.Email}</span></h6>
                                                    <h6>City : <span style={{ float: 'right' }}>{customer?.City}</span></h6>
                                                    <h6 className="d-flex justify-content-between align-items-center">
                                                        Schemes:
                                                        <Button variant="primary" onClick={() => setShowSchemesModal(true)}>View</Button>
                                                    </h6>
                                                    <h6 className="d-flex justify-content-between align-items-center">
                                                        Campaigns:
                                                        <Button variant="primary" onClick={() => setShowCampaignsModal(true)}>View</Button>
                                                    </h6>
                                                    <h6 className="d-flex justify-content-between align-items-center">
                                                        Last Tickets:
                                                        <Button variant="primary" onClick={() => setShowTicketsModal(true)}>View</Button>
                                                    </h6>
                                                    <h6 className="fw-bold" style={{ color: '#007bff' }}>Business Metrics:</h6>
                                                    <h6 style={{ color: '#007bff' }}>Average : <span style={{ float: 'right' }}>{businessMetrics.AverageBusiness}</span></h6>
                                                    <h6 style={{ color: '#007bff' }}>Prediction : <span style={{ float: 'right' }}>{businessMetrics.PredictedBusiness}</span></h6>
                                                    <h6 style={{ color: businessMetrics.Opportunity < 0 ? 'green' : 'red' }}>
                                                        Opportunity : <span style={{ float: 'right' }}>{businessMetrics.Opportunity}</span>
                                                    </h6>
                                                </div>
                                            </div>

                                            <Modal show={showTicketsModal} onHide={() => setShowTicketsModal(false)} size="xl">
                                                <Modal.Header closeButton>
                                                    <Modal.Title>Last Tickets</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <DataTable
                                                        columns={ticketColumns}
                                                        data={tickets}
                                                        pagination
                                                    />
                                                </Modal.Body>
                                            </Modal>


                                            <div className="row" style={{ padding: "0 20%" }}>
                                                <Button variant="outline-primary" onClick={handleCallClick} style={callSuccess ? { backgroundColor: 'green', color: 'white' } : {}}>
                                                    <i className="bi bi-telephone-outbound-fill" style={{ fontWeight: 'bold' }}> Click To Call</i>
                                                </Button>
                                            </div>


                                            <Modal show={showSchemesModal} onHide={() => setShowSchemesModal(false)}>
                                                <Modal.Header closeButton>
                                                    <Modal.Title>Schemes Details</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <div className="mc-card">
                                                        <div className="mc-dropdown">
                                                        </div>
                                                        <DataTable
                                                            columns={columns}
                                                            data={offers}
                                                            conditionalRowStyles={[
                                                                {
                                                                    when: row => row.OfferId == 1, // Condition to check
                                                                    style: {
                                                                        backgroundColor: 'rgba(63, 195, 128, 0.9)', // Greenish background for high discount
                                                                        color: 'white',
                                                                    },
                                                                },
                                                                {
                                                                    when: row => row.OfferId == 4,
                                                                    style: {
                                                                        backgroundColor: 'rgba(248, 148, 6, 0.9)', // Orangish background for low discount
                                                                        color: 'white',
                                                                    },
                                                                }
                                                            ]}
                                                            hover
                                                        />
                                                    </div>
                                                </Modal.Body>

                                            </Modal>

                                            {/* Campaigns Modal */}
                                            <Modal show={showCampaignsModal} onHide={() => setShowCampaignsModal(false)}>
                                                <Modal.Header closeButton>
                                                    <Modal.Title>Campaigns Details</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <DataTable
                                                        columns={CampaignsCol}
                                                        data={campaigns}
                                                        conditionalRowStyles={[
                                                            {
                                                                when: row => row.DiscountPercentage == 20, // Condition to check
                                                                style: {
                                                                    backgroundColor: 'rgba(63, 195, 128, 0.9)', // Greenish background for high discount
                                                                    color: 'white',
                                                                },
                                                            },
                                                            {
                                                                when: row => row.DiscountPercentage == 10,
                                                                style: {
                                                                    backgroundColor: 'rgba(248, 148, 6, 0.9)', // Orangish background for low discount
                                                                    color: 'white',
                                                                },
                                                            }
                                                        ]}
                                                        hover
                                                    />
                                                </Modal.Body>
                                                <Modal.Footer>
                                                    <Button variant="secondary" onClick={() => setShowCampaignsModal(false)}>Close</Button>
                                                </Modal.Footer>
                                            </Modal>

                                        </CardBody>
                                    </Card>
                                </Col>


                                {/* <Col md={8}>
        <CustomChart />
      </Col> */}

                                {showDispositionForm && (
                                    <Col md={8} >
                                        <Card >
                                            <CardHeader className="fw-bold " style={{ backgroundColor: "rgb(245 245 244)" }}>Interaction Details</CardHeader>
                                            <CardBody style={{ height: "65vh", overflowY: "auto" }} >
                                                <form onSubmit={handleSubmit}>
                                                    <div className="form-group mb-2">
                                                        <label className="form-label">Interaction Type</label>
                                                        <select
                                                            className="form-control"
                                                            name="TicketType"
                                                            value={formData.TicketType}
                                                            onChange={handleChange}
                                                        >
                                                            <option value="">Select Outcome</option>
                                                            <option value="Order">Order</option>
                                                            <option value="Contact Later">Contact Later</option>
                                                            <option value="Not Interested">Not Interested</option>
                                                        </select>
                                                    </div>
                                                    <div className="form-group mb-2" style={{ display: 'none' }}>
                                                        <label className="form-label">Ticket Source</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="TicketSource"
                                                            value="voice"
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                    <div className="form-group mb-2">
                                                        <label className="form-label">Subject</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="Subject"
                                                            value={formData.Subject}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                    <div className="form-group mb-2">
                                                        <label className="form-label">Interaction Description</label>
                                                        <textarea
                                                            className="form-control"
                                                            name="TicketDescription"
                                                            value={ticketDescription}
                                                            onChange={handleChange}
                                                            rows="3"
                                                        />
                                                    </div>
                                                    <div className="form-group mb-2" style={{ display: 'none' }}>
                                                        <label className="form-label">Priority</label>
                                                        <input
                                                            type="hidden"
                                                            className="form-control"
                                                            name="Priority"
                                                            value="Medium" // Set the value to "Medium"
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                    <div className="form-group mb-2" style={{ display: 'none' }}>
                                                        <label className="form-label">Severity</label>
                                                        <input
                                                            type="hidden"
                                                            className="form-control"
                                                            name="Severity"
                                                            value="Medium" // Set the value to "Medium"
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                    <div className="form-group mb-2" style={{ display: 'none' }}>
                                                        <label className="form-label">Category</label>
                                                        {/* <select */}
                                                        <input
                                                            className="form-control"
                                                            name="Category"
                                                            value="Sales"
                                                            onChange={handleChange}
                                                        />
                                                        {/* required> */}
                                                        {/* <option value="">Select Category</option>
                                <option value="Product">Product</option>
                                <option value="Logistics">Logistics</option>
                                <option value="Billing">Billing</option>
                                <option value="Sales">Sales</option>
                              </select> */}
                                                    </div>
                                                    <div className="form-group mb-2" style={{ display: 'none' }}>
                                                        <label className="form-label">SubCategory</label>
                                                        <input
                                                            className="form-control"
                                                            name="SubCategory"
                                                            value="Order"
                                                            onChange={handleChange}
                                                        />
                                                        {/* <select
                                className="form-control"
                                name="SubCategory"
                                value={formData.SubCategory}
                                onChange={handleChange}
                                required
                                disabled={!formData.Category}>
                                <option value="">Select SubCategory</option>
                                {formData.Category === "Product" && (
                                  <>
                                    <option value="Package Problem">Package Problem</option>
                                    <option value="Expired">Expired</option>
                                    <option value="Quantity">Quantity</option>
                                  </>
                                )}
                                {formData.Category === "Logistics" && (
                                  <>
                                    <option value="Delayed">Delivery</option>
                                    <option value="Not Traceable">Not Traceable</option>
                                  </>
                                )}
                                {formData.Category === "Billing" && (
                                  <>
                                    <option value="Excess">Excess</option>
                                    <option value="GST">GST</option>
                                  </>
                                )}
                                {formData.Category === "Sales" && (
                                  <>
                                    <option value="Enquiry">Enquiry</option>
                                    <option value="New Order">New Order</option>
                                    <option value="Order Modification">Order Modification</option>
                                  </>
                                )}
                              </select> */}
                                                    </div>
                                                    <div className="form-group mb-2" style={{ display: 'none' }}>
                                                        <label className="form-label">Owner</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="Owner"
                                                            value={formData.Owner}
                                                            onChange={handleChange}
                                                            readOnly
                                                        />
                                                    </div>
                                                    <div className="form-group mb-2" style={{ display: 'none' }}>
                                                        <label className="form-label">Assigned To</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="AssignedTo"
                                                            value={formData.AssignedTo}
                                                            onChange={handleChange}
                                                            readOnly
                                                        />
                                                    </div>
                                                    <div className="form-group mb-2">
                                                        <label className="form-label">Interaction Status</label>
                                                        <Form.Control as="select" name="TicketStatus" value={formData.TicketStatus} onChange={handleChange}>
                                                            <option value="">Select Ticket Status</option>
                                                            <option value="Open">Open</option>
                                                            <option value="In-Progress">In-Progress</option>
                                                            <option value="Closed">Closed</option>
                                                        </Form.Control>
                                                    </div>
                                                    <div className="form-group mb-2">
                                                        <label className="form-label">Due Date</label>
                                                        <input
                                                            type="date"  // Changed from "text" to "date"
                                                            className="form-control"
                                                            name="DueDate"
                                                            value={formData.DueDate}
                                                            onChange={handleChange}
                                                        />
                                                    </div>
                                                    <div className="form-group mb-2" style={{ display: 'none' }}>
                                                        <label className="form-label">Process</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="Process"
                                                            value={formData.Process}
                                                            readOnly
                                                        />
                                                    </div>
                                                    <div className="form-group mb-2" style={{ display: 'none' }}>
                                                        <label className="form-label">Team</label>
                                                        <input
                                                            type="text"
                                                            className="form-control"
                                                            name="Team"
                                                            value={formData.Team}
                                                            readOnly
                                                        />
                                                    </div>
                                                    <div className="form-group mb-2" style={{ visibility: 'hidden', height: 0, overflow: 'hidden' }}>
                                                        <label className="form-label">Escalated</label>
                                                        <input
                                                            type="checkbox"
                                                            className="form-check-input"
                                                            name="Escalated"
                                                            checked={formData.Escalated}
                                                            onChange={handleChange}
                                                        />
                                                        <label className="form-check-label">Escalated</label>
                                                    </div>
                                                    <input
                                                        type="hidden"
                                                        name="TenantId"
                                                        value={formData.TenantId}
                                                    />
                                                    <div className="text-center fw-bold">
                                                        <Button type="submit" variant="primary">Submit</Button>
                                                    </div>
                                                </form>
                                            </CardBody>
                                        </Card>
                                    </Col>
                                )}

                                <Col md={8}>
                                    <Card>
                                        <CardHeader className="fw-bold" style={{ backgroundColor: "rgb(245 245 244)" }}>Purchase History</CardHeader>
                                        <CardBody style={{ height: "65vh", overflowY: "auto" }} >
                                            <SalesChart />
                                        </CardBody>
                                    </Card>
                                </Col>
                                {/* <Col md={4} >
                    <Card >
                      <CardHeader className="fw-bold " style={{ backgroundColor: "rgb(245 245 244)" }}>Schemes</CardHeader>
                      <CardBody style={{ height: "40vh", overflowY: "auto" }} >
                        <DataTable columns={columns} data={offers} bordered scrolable>

                        </DataTable>
                      </CardBody>
                    </Card>
                  </Col>
                  <Col md={4} >
         
                    <Card>
                      <CardHeader className="fw-bold " style={{ backgroundColor: "rgb(245 245 244)" }}>Campaigns</CardHeader>
                      <CardBody style={{ height: "40vh", overflowY: "auto" }} >
                        <DataTable columns={CampaignsCol} data={campaigns} hover >

                        </DataTable>
                      </CardBody>
                    </Card>
                  </Col> */}
                                {/* </Row>
            <Row> */}
                                <Col md={4} >
                                    <Card>
                                        <CardHeader className="fw-bold " style={{ backgroundColor: "rgb(245 245 244)" }}>Order Details</CardHeader>
                                        <CardBody style={{ height: "43vh", overflowY: "auto" }} >
                                            <DataTable columns={order} data={orders}>

                                            </DataTable>
                                        </CardBody>
                                    </Card>
                                </Col>

                                <Col md={4}>
                                    <Card>
                                        <CardHeader className="fw-bold" style={{ backgroundColor: "rgb(245 245 244)" }}>Recommendation : {Note}</CardHeader>
                                        <CardBody style={{ height: "40vh", overflowY: "auto" }} >
                                            <RecommendationChart />
                                        </CardBody>
                                    </Card>
                                </Col>
                            </Row>
                        </>
                        {/* )} */}

                    </>
                )}
                <Modal show={interactionModalShow} onHide={() => setInteractionModalShow(false)} size="xl">
                    <Modal.Header closeButton>
                        <Modal.Title>Interaction Details</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <DataTable
                            columns={[
                                { name: 'Ticket Type', selector: row => row.TicketType },
                                { name: 'Ticket ID', selector: row => row.TicketId },
                                { name: 'Source', selector: row => row.TicketSource },
                                { name: 'Priority', selector: row => row.Priority },
                                { name: 'Severity', selector: row => row.Severity },
                                { name: 'Subject', selector: row => row.Subject },
                                { name: 'Category', selector: row => row.Category.join(', ') },
                                { name: 'SubCategory', selector: row => row.SubCategory.join(', ') },
                                { name: 'Status', selector: row => row.TicketStatus },
                                { name: 'Due Date', selector: row => new Date(row.DueDate).toLocaleDateString() },
                                // { name: 'Description', selector: row => row.TicketDescription }
                            ]}
                            data={interactionData.slice().reverse()} // Reverse the data array
                            customStyles={customStyles}
                            theme="default"
                            pagination
                        />
                    </Modal.Body>
                    <Modal.Footer>

                    </Modal.Footer>
                </Modal>
            </main>
        </>
    );
}
export default MainContent;