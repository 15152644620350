import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, LineElement, PointElement, Title, Tooltip, Legend } from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, LineElement, PointElement, Title, Tooltip, Legend);

const generateRandomData = (count, multiplier) => Array.from({ length: count }, () => Math.floor(Math.random() * multiplier));

const CustomChart = () => {
    const data = {
        labels: ['Jan 2018', 'Feb 2018', 'Mar 2018', 'Apr 2018', 'May 2018', 'Jun 2018', 'Jul 2018', 'Aug 2018', 'Sep 2018', 'Oct 2018', 'Nov 2018', 'Dec 2018', 'Jan 2019'],
        datasets: [
            {
                type: 'bar',
                label: 'Customers Lost',
                data: generateRandomData(13, 100),
                backgroundColor: 'rgba(75, 192, 192, 0.6)',
                yAxisID: 'y',
            },
            {
                type: 'line',
                label: 'Revenue Lost',
                data: generateRandomData(13, 100000),
                borderColor: 'rgba(75, 192, 192, 1)',
                borderWidth: 2,
                fill: false,
                yAxisID: 'y1',
            },
        ],
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            y: {
                type: 'linear',
                position: 'left',
                title: {
                    display: true,
                    text: '',
                },
            },
            y1: {
                type: 'linear',
                position: 'right',
                title: {
                    display: true,
                    text: '',
                },
                grid: {
                    drawOnChartArea: false,
                },
            },
        },
    };

    return (
        <div>
            <h2></h2>
            <div style={{ height: '100%', width: '100%', maxHeight: '500px', maxWidth: '1000px' }}>
                <Bar data={data} options={options} />
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-around', marginTop: '20px' }}>
                {/* Uncomment and update these sections if needed */}
                {/* <div style={{ textAlign: 'center' }}>
                    <h3>CHURN RATE</h3>
                    <p style={{ fontSize: '24px', fontWeight: 'bold' }}>2.01%</p>
                </div>
                <div style={{ textAlign: 'center' }}>
                    <h3>CUSTOMERS LOST</h3>
                    <p style={{ fontSize: '24px', fontWeight: 'bold' }}>33</p>
                </div>
                <div style={{ textAlign: 'center' }}>
                    <h3>REVENUE LOST</h3>
                    <p style={{ fontSize: '24px', fontWeight: 'bold' }}>$54,435</p>
                </div> */}
            </div>
        </div>
    );
};

export default CustomChart;