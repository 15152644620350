import relayService from "../Admin/AppProviders/Axios/hook";
import React, { useRef, useEffect, useState } from "react";
import { Button, Card, CardBody, CardHeader, CardTitle, Col, Dropdown, DropdownButton, Form, Modal, Row } from "react-bootstrap";
import DataTable from "react-data-table-component";
import { jwtDecode } from "jwt-decode";
import { ToastContainer, toast } from "react-toastify";
import { Bar } from 'react-chartjs-2';
import Chart from 'chart.js/auto';
import SalesChart from './SalesChart';
import CustomChart from "./CustomChart";

const InboundContent = () => {
    const [cardsVisible, setCardsVisible] = useState(false);
    const [showDispositionForm, setShowDispositionForm] = useState(false);
    const [isCalling, setIsCalling] = useState(false);
    const [layoutShrink, setLayoutShrink] = useState(false);
    const [showSchemesModal, setShowSchemesModal] = useState(false);
    const [showCampaignsModal, setShowCampaignsModal] = useState(false);
    const [showTicketsModal, setShowTicketsModal] = useState(false);
    const [businessMetrics, setBusinessMetrics] = useState({});


    const customStyles = {
        headCells: {
            style: {
                fontWeight: 'bold', // Make the header text bold
            },
        },

    };

    const formatDate = (dateString) => {
        const options = { year: 'numeric', month: '2-digit', day: '2-digit' };
        return new Date(dateString).toLocaleDateString(undefined, options);
    };

    const columns = [
        {
            name: <div style={{ fontWeight: "bold" }}>ID</div>,
            selector: row => row.OfferId,
            sortable: true,
            width: 'auto' // Adjust the width as needed
        },
        {
            name: <div style={{ textAlign: "center", fontWeight: "bold" }}>Name</div>,
            selector: row => row.Title,
            sortable: true,
            width: 'auto' // Adjust the width as needed
        },
        {
            name: <div style={{ textAlign: "center", fontWeight: "bold" }}>Start Date</div>,
            selector: row => formatDate(row.OStartDate),
            sortable: true,
            width: 'auto' // Adjust the width as needed
        },
        {
            name: <div style={{ textAlign: "center", fontWeight: "bold" }}>Discount</div>,
            selector: row => row.DiscountPercentage,
            sortable: true,
            width: 'auto' // Adjust the width as needed
        }
    ];

    const CampaignsCol = [
        {
            name: <div style={{ textAlign: "center", fontWeight: "bold" }}>ID</div>,
            selector: row => row.CampaignId,
            sortable: true
        },
        {
            name: <div style={{ textAlign: "center", fontWeight: "bold" }}>Name</div>,
            selector: row => row.Title,
            sortable: true
        },
        {
            name: <div style={{ textAlign: "center", fontWeight: "bold" }}>Type</div>,
            selector: row => row.CampaignType,
            sortable: true
        },
        {
            name: <div style={{ textAlign: "center", fontWeight: "bold" }}>Discount</div>,
            selector: row => row.DiscountPercentage,
            sortable: true
        }
    ];
    const ticketColumns = [
        {
            name: <div style={{ textAlign: "center", fontWeight: "bold" }}>Ticket ID</div>,
            selector: row => row.TicketId,
            sortable: true,
            width: 'auto'
        },
        {
            name: <div style={{ textAlign: "center", fontWeight: "bold" }}>Subject</div>,
            selector: row => row.Subject,
            sortable: true,
            width: 'auto'
        },
        {
            name: <div style={{ textAlign: "center", fontWeight: "bold" }}>Description</div>,
            selector: row => row.TicketDescription.text,
            sortable: true,
            width: 'auto'
        },
        {
            name: <div style={{ textAlign: "center", fontWeight: "bold" }}>Status</div>,
            selector: row => row.TicketStatus,
            sortable: true,
            width: 'auto'
        },
        {
            name: <div style={{ textAlign: "center", fontWeight: "bold" }}>Date</div>,
            selector: row => new Date(row.DueDate).toLocaleDateString(),
            sortable: true,
            width: 'auto'
        }
    
    ];

    const order = [
        {
            name: <div style={{ textAlign: "center", fontWeight: "bold" }}>Order ID</div>,
            selector: row => row.OrderId,
            sortable: true
        },
        {
            name: <div style={{ textAlign: "center", fontWeight: "bold" }}>Product</div>,
            selector: row => row.Product,
            sortable: true
        },
        {
            name: <div style={{ textAlign: "center", fontWeight: "bold" }}>Quantity</div>,
            selector: row => row.Quantity.toLocaleString(),
            sortable: true
        },
        {
            name: <div style={{ textAlign: "center", fontWeight: "bold" }}>UnitPrice</div>,
            selector: row => row.UnitPrice.toLocaleString(),
            sortable: true
        },
        {
            name: <div style={{ textAlign: "center", fontWeight: "bold" }}>TotalPrice</div>,
            selector: row => row.TotalPrice.toLocaleString(),
            sortable: true
        },
        {
            name: <div style={{ textAlign: "center", fontWeight: "bold" }}>SalesDate</div>,
            selector: row => new Date(row.SalesDate).toLocaleDateString(),
            sortable: true
        }
    ]
    // const handleBreakIn = () => {
    //     if (buttonText === 'BreakOut') {
    //         setButtonText('Break In');
    //         setButtonColor('primary');
    //         setSelectedOption('');
    //         setModalOpen(false);
    //     } else {
    //         setModalOpen(true);
    //     }
    // };


    const [serach, setSeacrch] = useState('')
    const intervalId = useRef(null);
    const [customer, setCustomer] = useState([])
    const [offers, setOffers] = useState([])
    const [campaigns, setCampaigns] = useState([])
    const [orders, setOrders] = useState([])
    const [interactionData, setInteractionData] = useState([]);
    const [outbound, setOutbound] = useState(true)
    const [interactionModalShow, setInteractionModalShow] = useState(false);

    const [selectedDropdown, setSelectedDropdown] = useState(localStorage.getItem('selectedDropdown') || 'Outbound');

    const [rid, setRid] = useState(null);
   
    const fetchCallData = () => {
        const url = `${process.env.REACT_APP_API_BASE_URL}/Voice/cdrreports?StartDate=${encodeURIComponent(new Date().toISOString())}&EndDate=${encodeURIComponent(new Date().toISOString())}&Live=true`;
        fetch(url)
            .then(response => response.json())
            .then(data => {
                console.log(data);
                if (data.data) {
                    let callerId = data.data[0].CallerId;
                    if (callerId.startsWith('0')) {
                        callerId = callerId.substring(1);
                    }

                    setIsLive(true);
                    clearInterval(intervalId.current);
                    console.log(callerId);
                    getCustomerDetails(callerId);
                    setCardsVisible(true);
                    setShowDispositionForm(true);
                } else {
                    setIsLive(false);
                    if (!intervalId.current) {
                        intervalId.current = setInterval(fetchCallData, 6000);
                    }
                }
            })
            .catch(error => {
                console.error('Error fetching call data:', error);
                if (!intervalId.current) {
                    intervalId.current = setInterval(fetchCallData, 4000);
                }
            });
    };

    useEffect(() => {
        const selectedDropdown = localStorage.getItem('selectedDropdown') || 'Outbound';
        if (selectedDropdown !== 'Inbound') {
            return;
        }

        fetchCallData();

        return () => {
            if (intervalId.current) {
                clearInterval(intervalId.current);
            }
        };
    }, []);


    const getCustomerDetails = async (callerId) => {
        console.log("Fetching customer details using Caller ID...");
        try {
            const customerResponse = await relayService({
                url: `/Customer/fetch`,
                method: "POST",
                headers: { "Content-Type": "application/json" },
                data: { TenantId: 0, Phone: callerId }, // Assuming the API can fetch by phone number
            });

            if (customerResponse && customerResponse.data?.message?.length > 0) {
                setCardsVisible(true);
                setOutbound(false);
                console.log(customerResponse, "response");
                setCustomer(customerResponse.data.message[0]);
                const customerId = customerResponse.data.message[0].CustomerId;
                const Phone = customerResponse.data.message[0].Phone;
                localStorage.setItem('customerId', customerId); // Store customerId in localStorage for later use
                localStorage.setItem('Phone', Phone);
                getOffers();
                getCampaigns();
                getOrders(customerId);
            }
        } catch (err) {
            console.error("Error fetching customer data:", err);
        }
    };


    const getOffers = async () => {
        console.log("response");
        try {
            // Make a GET request to retrieve Customer Details
            const response = await relayService({
                url: `/Offers/fetch`,
                method: "POST",
                headers: { "Content-Type": "application/json" },
                data: { TenantId: 0 },
            });
            if (response) {
                if (response?.data?.message?.length > 0) {
                    console.log(response, "offers");
                    setOffers(response?.data?.message);
                    //return response;
                }
            }
        } catch (err) {
            // Show error message if request fails
        }
    };

    const getCampaigns = async () => {
        console.log("response");
        try {
            // Make a GET request to retrieve Customer Details
            const response = await relayService({
                url: `/Campaigns/fetch`,
                method: "POST",
                headers: { "Content-Type": "application/json" },
                data: { TenantId: 0 },
            });
            if (response) {
                if (response?.data?.message?.length > 0) {
                    console.log(response, "campaigns");
                    setCampaigns(response?.data?.message);
                }
            }
        } catch (err) {
            // Show error message if request fails
        }
    };

    let Note = localStorage.getItem('Notes');
    console.log("Note", Note);

    const getOrders = async (customerId) => {
        console.log("Fetching orders for Customer ID:", customerId);
        try {
            const response = await relayService({
                url: `/Sales/fetch`,
                method: "POST",
                headers: { "Content-Type": "application/json" },
                data: { TenantId: 0, CustomerId: customerId },
            });
            if (response && response.data?.message?.length > 0) {
                console.log(response, "sales");
                setOrders(response.data.message);
            }
        } catch (err) {
            console.error("Error fetching order data:", err);
        }
    };

    useEffect(() => {
        if (showTicketsModal) {
            fetchTickets();
        }
    }, [showTicketsModal]);
    const maskedPhone = customer?.Phone ? customer.Phone.slice(0, 0) + '*****' + customer.Phone.slice(-5) : '';
    // Function to fetch tickets
    const fetchTickets = async () => {
        const phone = localStorage.getItem('Phone');
        try {
            const response = await relayService({
                url: '/tickets/fetch',
                method: 'POST',
                headers: { 'Content-Type': 'application/json' },
                data: { TenantId: 0, Owner: phone }
            });
            if (response.data && response.data.message) {
                setTickets(response.data.message);
            }
        } catch (error) {
            console.error('Failed to fetch tickets:', error);
        }
    };
    
    // Add state for tickets data
    const [tickets, setTickets] = useState([]);

    const handleCallClick = async () => {
        if (isCalling) return;
        setIsCalling(true);
        setIsCalling(true);
        setLayoutShrink(true);
        toast.success('Call initiated!');

        const token = localStorage.getItem('token');
        const decoded = jwtDecode(token);
        const sipPhone = `${decoded.userId.SipPhone}`;
        const actionID = '1234567';
        const phoneNumber = customer?.Phone;
        const url = `/Voice/c2cSIP?ActionID=${encodeURIComponent(actionID)}&sipAgentID=${encodeURIComponent(sipPhone)}&NumberToBeCalled=${encodeURIComponent(phoneNumber)}`;

        try {
            const response = await relayService({
                url: url,
                method: "GET"
            });
            console.log('Call initiated:', response.data);
            toast.success('Call initiated successfully!');
            setShowDispositionForm(true);
        } catch (error) {
            console.error('Error initiating call:', error);
            toast.error('Error initiating call.');
            setShowDispositionForm(true);
        } finally {
            setIsCalling(false);
        }
    };

    const [inBoundCall, setInBoundCAll] = useState([])
    const [callerId, setCallerId] = useState('');
    const [isLive, setIsLive] = useState(false);

    const [formData, setFormData] = useState({
        TenantId: 0,
        TicketType: '',
        TicketSource: 'Voice',
        Subject: '',
        TicketDescription: '',
        Priority: 'Medium',
        Severity: 'Medium',
        Category: '',
        SubCategory: '',
        Owner: '',
        AssignedTo: '',
        TicketStatus: '',
        DueDate: '',
        Process: 'Adani',
        Team: 'Adani',
        Escalated: "on" ? true : false,
    });
    const [ticketDescription, setTicketDescription] = useState('');
    useEffect(() => {
        const token = localStorage.getItem('token');
        const decoded = jwtDecode(token);
        const phone = localStorage.getItem('Phone'); 
        setFormData(prevState => ({
            ...prevState,
            Owner: phone,
            AssignedTo: decoded.userId.UserName
        }));
    }, []);
    const handleChange = (e) => {
        const { name, type, checked, value } = e.target;
        let formattedValue = value;
        if (name === "DueDate") {
            formattedValue = new Date(value).toISOString();
        }
        if (type === "checkbox") {
            setFormData(prevState => ({
                ...prevState,
                [name]: checked
            }));
        } else if (name === "TicketDescription") {
            setTicketDescription(value);
        } else {
            setFormData(prevState => ({
                ...prevState,
                [name]: value
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const submissionData = {
                ...formData,
                TicketDescription: { text: ticketDescription } // Ensure TicketDescription is an object
            };
            const response = await relayService({
                url: '/tickets/add',
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                data: submissionData
            });
            const data = await response.data;
            console.log('Submission successful', data);
           // const fetchedRid = localStorage.getItem('fetchedRid');
            // Additional API call to update SalesCampaigns
            // const updateResponse = await relayService({
            //     url: `/SalesCampaigns/update?rid=${fetchedRid}`,
            //     method: 'POST',
            //     headers: {
            //         'Content-Type': 'application/json'
            //     },
            //     data: {
            //         TenantId: 0,
            //         UserFetchTime: new Date().toISOString().split('T')[0],
            //         Active: false,
            //         Tickets: data.message.TicketId,
            //         Attemps: 1
            //     }
            // });

            setCardsVisible(false);
            toast.success('Ticket added successfully!');
            setLayoutShrink(false);
            setShowDispositionForm(false);
            setFormData({
                TenantId: 0,
                TicketType: '',
                TicketSource: '',
                Subject: '',
                TicketDescription: '',
                Priority: '',
                Severity: '',
                Category: '',
                SubCategory: '',
                Owner: '',
                AssignedTo: '',
                TicketStatus: '',
                DueDate: '',
                Process: 'Adani',
                Team: 'Adani',
                Escalated: false,
            });
            setTicketDescription('');
         //   localStorage.removeItem('fetchedRid');
            localStorage.removeItem('customerId');
            localStorage.removeItem('Notes');
            localStorage.removeItem('Phone');
            fetchCallData();
        } catch (error) {
            console.error('Error submitting form', error);
            toast.error('Error submitting ticket.');
        }
    };

    useEffect(() => {
        const fetchBusinessMetrics = async () => {
            try {
                const response = await relayService({
                    url: 'ZAIServices/GetCustomerPurchaseSummary',
                    method: 'GET'
                });

                if (response.data.Status === "Success") {
                    setBusinessMetrics(response.data.message);
                } else {
                    toast.error("Failed to fetch business metrics");
                }
            } catch (error) {
                toast.error("Error fetching business metrics");
            }
        };

        fetchBusinessMetrics();
    }, []);

    const RecommendationChart = () => {
        const canvasRef = useRef(null);

        useEffect(() => {
            const ctx = canvasRef.current.getContext('2d');
            const chart = new Chart(ctx, {
                type: 'bar',
                data: {
                    labels: ['January', 'February', 'March', 'April', 'May', 'June'],
                    datasets: [{
                        label: 'Cement Sales',
                        data: [65, 59, 80, 81, 56, 55],
                        backgroundColor: [
                            'rgba(255, 99, 132, 0.2)',
                            'rgba(54, 162, 235, 0.2)',
                            'rgba(255, 206, 86, 0.2)',
                            'rgba(75, 192, 192, 0.2)',
                            'rgba(153, 102, 255, 0.2)',
                            'rgba(255, 159, 64, 0.2)'
                        ],
                        borderColor: [
                            'rgba(255, 99, 132, 1)',
                            'rgba(54, 162, 235, 1)',
                            'rgba(255, 206, 86, 1)',
                            'rgba(75, 192, 192, 1)',
                            'rgba(153, 102, 255, 1)',
                            'rgba(255, 159, 64, 1)'
                        ],
                        borderWidth: 1
                    }]
                },
                options: {
                    scales: {
                        y: {
                            beginAtZero: true
                        }
                    }
                }
            });

            return () => chart.destroy();
        }, []);

        return <canvas ref={canvasRef}></canvas>;
    };

    return (
        <>
            <ToastContainer position="top-right" autoClose={5000} hideProgressBar={false} newestOnTop={false} closeOnClick rtl={false} pauseOnFocusLoss draggable pauseOnHover />

            {cardsVisible && (
                <>
                    {/* {fetchClicked && ( */}
                    <>
                        <Row className="g-2">
                            <Col md={4} className="">
                                <Card>
                                    <CardHeader className="fw-bold" style={{ backgroundColor: "rgb(245 245 244)", marginBottom: "8px" }}>
                                        Customer Details
                                    </CardHeader>
                                    <CardBody style={{ height: "64vh", overflowY: "auto" }} >
                                        <div className="row" >

                                            <div className="col-md-12" style={{ padding: "5px 10%", borderLeft: "5px solid #198754" }}>

                                                <h6>Name : <span style={{ float: 'right' }}>{customer?.CustomerName}</span></h6>
                                                <h6>Number : <span style={{ float: 'right' }}>{maskedPhone}</span></h6>
                                                {/* {isLive && <h6>Number : <span style={{ float: 'right' }}>{callerId}</span></h6>} */}

                                                <h6>Customer Type : <span style={{ float: 'right', color: 'red' }}>{customer?.CustomerType}</span></h6>
                                                <h6>Email : <span style={{ float: 'right' }}>{customer?.Email}</span></h6>
                                                <h6>City : <span style={{ float: 'right' }}>{customer?.City}</span></h6>
                                                <h6 className="d-flex justify-content-between align-items-center">
                                                    Schemes:
                                                    <Button variant="primary" onClick={() => setShowSchemesModal(true)}>View</Button>
                                                </h6>
                                                <h6 className="d-flex justify-content-between align-items-center">
                                                    Campaigns:
                                                    <Button variant="primary" onClick={() => setShowCampaignsModal(true)}>View</Button>
                                                </h6>
                                                <h6 className="d-flex justify-content-between align-items-center">
                                                        Last Tickets:
                                                        <Button variant="primary" onClick={() => setShowTicketsModal(true)}>View</Button>
                                                    </h6>
                                                    <h6 className="fw-bold" style={{ color: '#007bff' }}>Business Metrics:</h6>
                                                    <h6 style={{ color: '#007bff' }}>Average : <span style={{ float: 'right' }}>{businessMetrics.AverageBusiness}</span></h6>
                                                    <h6 style={{ color: '#007bff' }}>Prediction : <span style={{ float: 'right' }}>{businessMetrics.PredictedBusiness}</span></h6>
                                                    <h6 style={{ color: businessMetrics.Opportunity < 0 ? 'green' : 'red' }}>
                                                        Opportunity : <span style={{ float: 'right' }}>{businessMetrics.Opportunity}</span>
                                                    </h6>
                                                </div>
                                            </div>

                                            <Modal show={showTicketsModal} onHide={() => setShowTicketsModal(false)} size="xl">
                                                <Modal.Header closeButton>
                                                    <Modal.Title>Last Tickets</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>
                                                    <DataTable
                                                        columns={ticketColumns}
                                                        data={tickets}
                                                        pagination
                                                    />
                                                </Modal.Body>
                                            </Modal>


                                        {/* <div className="row" style={{ padding: "0 20%" }}>
                                                    <Button variant="outline-primary" onClick={handleCallClick}>
                                                        <i className="bi bi-telephone-outbound-fill" style={{ fontWeight: 'bold' }}> Click To Call</i>
                                                    </Button>
                                                </div> */}


                                        <Modal show={showSchemesModal} onHide={() => setShowSchemesModal(false)}>
                                            <Modal.Header closeButton>
                                                <Modal.Title>Schemes Details</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <div className="mc-card">
                                                    <div className="mc-dropdown">
                                                    </div>
                                                    <DataTable
                                                        columns={columns}
                                                        data={offers}
                                                        customStyles={{
                                                            headCells: {
                                                                style: {
                                                                    fontWeight: 'bold',
                                                                    textAlign: 'center',
                                                                },
                                                            },
                                                        }}
                                                    />
                                                </div>
                                            </Modal.Body>
                                        </Modal>
                                        <Modal show={showCampaignsModal} onHide={() => setShowCampaignsModal(false)}>
                                            <Modal.Header closeButton>
                                                <Modal.Title>Campaigns Details</Modal.Title>
                                            </Modal.Header>
                                            <Modal.Body>
                                                <DataTable columns={CampaignsCol} data={campaigns} hover />
                                            </Modal.Body>
                                            <Modal.Footer>
                                                <Button variant="secondary" onClick={() => setShowCampaignsModal(false)}>Close</Button>
                                            </Modal.Footer>
                                        </Modal>

                                    </CardBody>
                                </Card>
                            </Col>
                            {showDispositionForm && (
                                <Col md={8} >
                                    <Card>
                                        <CardHeader className="fw-bold " style={{ backgroundColor: "rgb(245 245 244)" }}>Interaction Details</CardHeader>
                                        <CardBody style={{ height: "65vh", overflowY: "auto" }} >
                                            <form onSubmit={handleSubmit}>
                                                <div className="form-group mb-2">
                                                    <label className="form-label">Ticket Type</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="TicketType"
                                                        value={formData.TicketType}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                <div className="form-group mb-2">
                                                    <label className="form-label">Ticket Source</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="TicketSource"
                                                        value={formData.TicketSource}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                <div className="form-group mb-2">
                                                    <label className="form-label">Subject</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="Subject"
                                                        value={formData.Subject}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                <div className="form-group mb-2">
                                                    <label className="form-label">Ticket Description</label>
                                                    <textarea
                                                        className="form-control"
                                                        name="TicketDescription"
                                                        value={ticketDescription}
                                                        onChange={handleChange}
                                                        rows="3"
                                                    />
                                                </div>
                                                <div className="form-group mb-2">
                                                    <label className="form-label">Priority</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="Priority"
                                                        value={formData.Priority}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                <div className="form-group mb-2">
                                                    <label className="form-label">Severity</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="Severity"
                                                        value={formData.Severity}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                <div className="form-group mb-2">
                                                    <label className="form-label">Category</label>
                                                    <select
                                                        className="form-control"
                                                        name="Category"
                                                        value={formData.Category}
                                                        onChange={handleChange}
                                                        required>
                                                        <option value="">Select Category</option>
                                                        <option value="Product">Product</option>
                                                        <option value="Logistics">Logistics</option>
                                                        <option value="Billing">Billing</option>
                                                        <option value="Sales">Sales</option>
                                                    </select>
                                                </div>
                                                <div className="form-group mb-2">
                                                    <label className="form-label">SubCategory</label>
                                                    <select
                                                        className="form-control"
                                                        name="SubCategory"
                                                        value={formData.SubCategory}
                                                        onChange={handleChange}
                                                        required
                                                        disabled={!formData.Category}>
                                                        <option value="">Select SubCategory</option>
                                                        {formData.Category === "Product" && (
                                                            <>
                                                                <option value="Package Problem">Package Problem</option>
                                                                <option value="Expired">Expired</option>
                                                                <option value="Quantity">Quantity</option>
                                                            </>
                                                        )}
                                                        {formData.Category === "Logistics" && (
                                                            <>
                                                                <option value="Delayed">Delayed</option>
                                                                <option value="Not Traceable">Not Traceable</option>
                                                            </>
                                                        )}
                                                        {formData.Category === "Billing" && (
                                                            <>
                                                                <option value="Excess">Excess</option>
                                                                <option value="GST">GST</option>
                                                            </>
                                                        )}
                                                        {formData.Category === "Sales" && (
                                                            <>
                                                                <option value="Enquiry">Enquiry</option>
                                                                <option value="New Order">New Order</option>
                                                                <option value="Order Modification">Order Modification</option>
                                                            </>
                                                        )}
                                                    </select>
                                                </div>
                                                <div className="form-group mb-2" style={{ display: 'none' }}>
                                                    <label className="form-label">Owner</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="Owner"
                                                        value={formData.Owner}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                <div className="form-group mb-2" style={{ display: 'none' }}>
                                                    <label className="form-label">Assigned To</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="AssignedTo"
                                                        value={formData.AssignedTo}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                <div className="form-group mb-2">
                                                    <label className="form-label">Ticket Status</label>
                                                    <select
                                                        className="form-control"
                                                        name="TicketStatus"
                                                        value={formData.TicketStatus}
                                                        onChange={handleChange}>
                                                        <option value="">Select Ticket Status</option>
                                                        <option value="Open">Open</option>
                                                        <option value="In-Progress">In-Progress</option>
                                                        <option value="Closed">Closed</option>
                                                    </select>
                                                </div>
                                                <div className="form-group mb-2">
                                                    <label className="form-label">Due Date</label>
                                                    <input
                                                        type="date"  // Changed from "text" to "date"
                                                        className="form-control"
                                                        name="DueDate"
                                                        value={formData.DueDate}
                                                        onChange={handleChange}
                                                    />
                                                </div>
                                                <div className="form-group mb-2" style={{ display: 'none' }}>
                                                    <label className="form-label">Process</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="Process"
                                                        value={formData.Process}
                                                        readOnly
                                                    />
                                                </div>
                                                <div className="form-group mb-2" style={{ display: 'none' }}>
                                                    <label className="form-label">Team</label>
                                                    <input
                                                        type="text"
                                                        className="form-control"
                                                        name="Team"
                                                        value={formData.Team}
                                                        readOnly
                                                    />
                                                </div>
                                                <div className="form-group mb-2"  style={{ visibility: 'hidden', height: 0, overflow: 'hidden' }}>
                                                    <label className="form-label"></label>
                                                    <input
                                                        type="checkbox"
                                                        className="form-check-input"
                                                        name="Escalated"
                                                        checked={formData.Escalated}
                                                        onChange={handleChange}
                                                    />
                                                    <label className="form-check-label">Escalated</label>
                                                </div>
                                                <input
                                                    type="hidden"
                                                    name="TenantId"
                                                    value={formData.TenantId}
                                                />
                                                <div className="text-center fw-bold">
                                                    <Button type="submit" variant="primary">Submit</Button>
                                                </div>
                                            </form>
                                        </CardBody>
                                    </Card>
                                </Col>
                            )}

                            <Col md={8}>
                                <Card>
                                    <CardHeader className="fw-bold" style={{ backgroundColor: "rgb(245 245 244)" }}>Purchase History</CardHeader>
                                    <CardBody style={{ height: "65vh", overflowY: "auto" }} >
                                        <SalesChart />
                                    </CardBody>
                                </Card>
                            </Col>
                            <Col md={4} >
                                <Card>
                                    <CardHeader className="fw-bold " style={{ backgroundColor: "rgb(245 245 244)" }}>Order Details</CardHeader>
                                    <CardBody style={{ height: "43vh", overflowY: "auto" }} >
                                        <DataTable columns={order} data={orders}>

                                        </DataTable>
                                    </CardBody>
                                </Card>
                            </Col>

                            <Col md={4}>
                                <Card>
                                    <CardHeader className="fw-bold" style={{ backgroundColor: "rgb(245 245 244)" }}>Recommendation : {Note}</CardHeader>
                                    <CardBody style={{ height: "40vh", overflowY: "auto" }} >
                                        <RecommendationChart />
                                    </CardBody>
                                </Card>
                            </Col>
                        </Row>
                    </>
                    {/* )} */}
                </>
            )}
             {/* {!cardsVisible && (
            <Button onClick={handleBreakIn} variant="primary">Break In</Button>
        )} */}
            <Modal show={interactionModalShow} onHide={() => setInteractionModalShow(false)} size="xl">
                <Modal.Header closeButton>
                    <Modal.Title>Interaction Details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <DataTable
                        columns={[
                            { name: 'Ticket Type', selector: row => row.TicketType },
                            { name: 'Ticket ID', selector: row => row.TicketId },
                            { name: 'Source', selector: row => row.TicketSource },
                            { name: 'Priority', selector: row => row.Priority },
                            { name: 'Severity', selector: row => row.Severity },
                            { name: 'Subject', selector: row => row.Subject },
                            { name: 'Category', selector: row => row.Category.join(', ') },
                            { name: 'SubCategory', selector: row => row.SubCategory.join(', ') },
                            { name: 'Status', selector: row => row.TicketStatus },
                            { name: 'Due Date', selector: row => new Date(row.DueDate).toLocaleDateString() },
                            // { name: 'Description', selector: row => row.TicketDescription }
                        ]}
                        data={interactionData}
                        customStyles={customStyles}
                        theme="default"
                        pagination
                    />
                </Modal.Body>
                <Modal.Footer>
                </Modal.Footer>
            </Modal>

        </>
    );
}
export default InboundContent;