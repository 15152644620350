const industryData = [
  {
    Month: "Jan-23",
    Production: 825,
    Sales: 823,
    demand: 835,
    population: 140.8,
    gdp: 338282.98,
    disbusment: 215502.57,
    interestrate: 8.1,
  },
  {
    Month: "Feb-23",
    Production: 848,
    Sales: 836,
    demand: 853,
    population: 140.8,
    gdp: 313161.38,
    disbusment: 217568.62,
    interestrate: 8.14,
  },
  {
    Month: "Mar-23",
    Production: 531,
    Sales: 514,
    demand: 625,
    population: 140.9,
    gdp: 288039.68,
    disbusment: 219634.66,
    interestrate: 8.18,
  },
  {
    Month: "Apr-23",
    Production: 487,
    Sales: 477,
    demand: 495,
    population: 141,
    gdp: 262917.98,
    disbusment: 221700.71,
    interestrate: 8.23,
  },
  {
    Month: "May-23",
    Production: 531,
    Sales: 514,
    demand: 596,
    population: 141.1,
    gdp: 261644.65,
    disbusment: 223766.76,
    interestrate: 8.27,
  },
  {
    Month: "Jun-23",
    Production: 586,
    Sales: 574,
    demand: 685,
    population: 141.2,
    gdp: 260371.32,
    disbusment: 225832.8,
    interestrate: 8.31,
  },
  {
    Month: "Jul-23",
    Production: 434,
    Sales: 430,
    demand: 547,
    population: 141.3,
    gdp: 259097.99,
    disbusment: 227898.85,
    interestrate: 8.35,
  },
  {
    Month: "Aug-23",
    Production: 422,
    Sales: 419,
    demand: 526,
    population: 141.4,
    gdp: 257824.66,
    disbusment: 229964.9,
    interestrate: 8.39,
  },
  {
    Month: "Sep-23",
    Production: 433,
    Sales: 431,
    demand: 502,
    population: 141.5,
    gdp: 256551.33,
    disbusment: 232030.94,
    interestrate: 8.43,
  },
  {
    Month: "Oct-23",
    Production: 453,
    Sales: 451,
    demand: 625,
    population: 141.6,
    gdp: 255278,
    disbusment: 234096.99,
    interestrate: 8.48,
  },
  {
    Month: "Nov-23",
    Production: 903,
    Sales: 895,
    demand: 900,
    population: 141.7,
    gdp: 254004.67,
    disbusment: 236163.04,
    interestrate: 8.6,
  },
];

export default industryData